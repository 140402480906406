import Vue from 'vue'

// Component imports
import BaseInput from '@/core/components/BaseInput'
import BaseSearchInput from '@/core/components/BaseSearchInput'
import BaseTextarea from '@/core/components/BaseTextarea'
import BaseCheckbox from '@/core/components/BaseCheckbox'
import BaseSelect from '@/core/components/BaseSelect'
import BaseButton from '@/core/components/BaseButton'
import BaseModal from '@/core/components/BaseModal'
import BaseLinkButton from '@/core/components/BaseLinkButton'
import BaseChip from '@/core/components/BaseChip'
import BaseHeader from '@/core/components/BaseHeader'
import BaseUserSearch from '@/core/components/BaseUserSearch'
import BaseRoleSearch from '@/core/components/BaseRoleSearch'

// Component globals
Vue.component('BaseInput', BaseInput)
Vue.component('BaseSearchInput', BaseSearchInput)
Vue.component('BaseTextarea', BaseTextarea)
Vue.component('BaseCheckbox', BaseCheckbox)
Vue.component('BaseSelect', BaseSelect)
Vue.component('BaseButton', BaseButton)
Vue.component('BaseModal', BaseModal)
Vue.component('BaseLinkButton', BaseLinkButton)
Vue.component('BaseChip', BaseChip)
Vue.component('BaseHeader', BaseHeader)
Vue.component('BaseUserSearch', BaseUserSearch)
Vue.component('BaseRoleSearch', BaseRoleSearch)