import Api from '@/core/api'

export default {
	login(credentials) {
		return Api().post('login', credentials)
	},
	register(credentials) {
		return Api().post('register', credentials)
	},
	logout() {
		return Api().post('logout')
	},
	status() {
		return Api().get('status')
	},
}
