import addRoutes from '@/core/helpers/addRoutes'
import {isAlreadyLoggedIn} from '@/core/guards/auth.guard'

addRoutes([
	{
		path: '/lost_password',
		name: 'LostPassword',
		component: () => import(/* webpackChunkName: "LostPassword" */ '@/modules/lost_password/views/LostPassword.vue'),
		beforeEnter: isAlreadyLoggedIn,
	},
])