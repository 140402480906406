<template>
	<div class="textarea-class" :class="{tight}">
		<label :for="$attrs.id">
			<slot></slot>
			<span v-if="required">*</span>
		</label>
		<textarea
			v-bind="$attrs"
			:placeholder="placeholder"
			:value="value"
			@input="(e) => $emit('input', e.target.value)"
		/>
	</div>
</template>

<script>
export default {
	name: 'BaseTextarea',
	props: {
		value: [String, Number],
		required: {
			type: Boolean,
			default: false,
		},
		tight: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		if (this.$attrs.placeholder) {
			this.placeholder = this.$attrs.placeholder
		} else {
			this.placeholder = this.$slots.default[0].text
		}
	},
	data: () => ({
		placeholder: null,
	}),
}
</script>

<style scoped>
.textarea-class {
	@apply text-sm;
}

label {
	@apply block font-medium;
}

textarea {
	@apply border border-gray-200;
	@apply w-full rounded px-4 py-3 mt-2;
}

textarea:focus {
	@apply outline-none;
}
</style>
