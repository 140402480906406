import addRoutes from '@/core/helpers/addRoutes'
import {isAlreadyLoggedIn} from '@/core/guards/auth.guard'

addRoutes([
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "Register" */ '@/modules/register/views/Register.vue'),
		beforeEnter: isAlreadyLoggedIn,
	},
])