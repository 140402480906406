<template>
	<div class="input-class">
		<label :for="$attrs.id">
			<slot></slot>
			<span v-if="required">*</span>
		</label>
		<div class="relative">
			<input
				v-bind="$attrs"
				:required="required"
				:placeholder="placeholder"
				v-model="val"
			>
			<button
				type="button"
				class="clear-btn"
				title="Rensa"
				tabindex="-1"
				@click="clearValue"
				v-if="showClear($attrs)"
			>
				<XIcon size="15"/>
			</button>
		</div>
	</div>
</template>

<script>
import {XIcon} from '@vue-hero-icons/outline'

export default {
	name: 'BaseInput',
	components: {XIcon},
	props: {
		value: [String, Number],
		required: {
			type: Boolean,
			default: false,
		},
		tight: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		placeholder: null,
	}),
	computed: {
		val: {
			set(newValue) {
				this.$emit('input', newValue)
			},
			get() {
				return this.value
			},
		},
	},
	methods: {
		showClear(attrs) {
			return this.val.length
				&& !Object.keys(attrs).includes('readonly')
				&& !Object.keys(attrs).includes('disabled')
		},
		clearValue() {
			this.val = ''
		},
	},
	created() {
		if (this.$attrs.placeholder) {
			this.placeholder = this.$attrs.placeholder
		} else {
			this.placeholder = this.$slots.default[0].text
		}
	},
}
</script>

<style scoped>
.input-class {
	@apply text-sm;
}

label {
	@apply block font-medium;
}

input {
	@apply border border-gray-200;
	@apply w-full rounded px-4 py-3 mt-2;
}

input:focus {
	@apply outline-none;
}

input[readonly] {
	@apply bg-gray-300;
}

.clear-btn {
	@apply text-gray-500;
	@apply absolute right-4 top-6 z-50;
}

.clear-btn:focus {
	@apply outline-none;
}
</style>
