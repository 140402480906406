<template>
	<div class="header-wrapper">
		<header>
			<router-link to="/">
				<h1>DinAnmälan</h1>
			</router-link>
			<nav v-if="isLoggedIn">
				<!-- Only here during development -->
				<router-link to="/activity/create">
					Skapa aktivitet
				</router-link>

				<router-link to="/profile/general">
					{{ user.first_name }} {{ user.last_name }}
				</router-link>

				<!-- Only here during development -->
				<button type="button" @click="logout">
					Logga ut
				</button>
			</nav>
			<nav v-else>
				<router-link to="/login" class="login-btn" v-if="!isLoggedIn">
					Logga in
				</router-link>
			</nav>
		</header>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: 'TheHeader',
	computed: {
		...mapGetters({
			user: 'AuthStore/user',
			isLoggedIn: 'AuthStore/isLoggedIn',
		}),
	},
	methods: {
		logout() {
			this.$store.dispatch('AuthStore/logout')
		},
	},
}
</script>

<style scoped>
.header-wrapper {
	@apply absolute top-0 inset-x-0;
	@apply h-16 bg-primary text-white;
}

header {
	@apply container mx-auto px-5;
	@apply h-full flex items-center justify-between;
}

nav {
	@apply text-sm tracking-wider;
	@apply flex items-center gap-10;
}

.login-btn {
	@apply text-black font-semibold;
	@apply bg-white py-3 px-8 rounded;
}
</style>