import basicApiCall from '@/core/helpers/basicApiCall'
import AuthService from '@/core/services/AuthService'
import router from '@/core/router'

export default {
	namespaced: true,
	state: {
		user: null,
		token: null,
	},
	actions: {
		async login({commit}, user) {
			const {data, status} = await basicApiCall(AuthService.login(user))
			if (status === 200) {
				commit('SET_USER', data.user)
				commit('SET_TOKEN', data.access_token)
				await router.replace({name: 'Home'})
			}
		},
		async register({commit}, user) {
			const {data, status} = await basicApiCall(AuthService.register(user))
			if (status === 200) {
				commit('SET_USER', data.user)
				commit('SET_TOKEN', data.access_token)
				await router.replace({name: 'Home'})
			}
		},
		async logout({commit}) {
			const {status} = await basicApiCall(AuthService.logout())
			if (status === 200) {
				commit('CLEAR_USER')
				await router.replace({name: 'Home'})
			}
		},
		async status({commit, state}) {
			if (!state.token && !state.user) {
				return;
			}

			const {status} = await basicApiCall(AuthService.status())
			if (status !== 200) commit('CLEAR_USER')
		},
	},
	mutations: {
		SET_USER(state, user) {
			state.user = user
		},
		SET_TOKEN(state, token) {
			state.token = token
		},
		CLEAR_USER(state) {
			state.user = null
			state.token = null
		},
	},
	getters: {
		user: (state) => state.user,
		token: (state) => state.token,
		isLoggedIn: (state) => !!state.token,
	},
}
