import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker.js'
import router from '@/core/router.js'
import store from '@/core/store.js'
import '@/assets/styles/tailwind.css'
import '@/assets/styles/app.css'
import '@/assets/styles/auth.css'
import '@/assets/styles/fonts.css'
import '@/assets/styles/tables.css'
import '@/core/imports/modules'
import '@/core/imports/components'

Vue.config.productionTip = false

function init() {
	new Vue({
		router,
		store,
		render: h => h(App),
	}).$mount('#app')
}

store.dispatch('AuthStore/status')
	.then(() => init())
