import Api from '@/core/api'

export default {
	index() {
		return Api().get('activities')
	},
	view(id) {
		return Api().get(`activities/${id}`)
	},
	create(data) {
		return Api().post('activities', data)
	},
}
