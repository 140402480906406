<template>
	<div class="bg-gray-25">
		<TheHeader v-if="showHeader"/>
		<div class="min-h-screen">
			<router-view/>
		</div>
		<TheFooter/>
	</div>
</template>

<script>
import TheHeader from '@/core/components/TheHeader'
import TheFooter from '@/core/components/TheFooter'

export default {
	name: 'App',
	components: {
		TheFooter,
		TheHeader,
	},
	computed: {
		showHeader() {
			const routeName = this.$route.name
			return routeName !== 'Login'
				&& routeName !== 'Register'
				&& routeName !== 'LostPassword'
		},
	},
}
</script>
