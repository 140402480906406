<template>
	<div class="select-class">
		<label :for="$attrs.id">
			<slot></slot>
			<span v-if="required">*</span>
		</label>
		<select :name="$attrs.id" :id="$attrs.id" v-model="val" :required="required">
			<option value="">Välj</option>
			<option :value="option.id" v-for="option in options" :key="option.id">
				{{ option.title }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	name: 'BaseSelect',
	props: {
		value: {
			type: [String, Number],
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		options: Array,
	},
	computed: {
		val: {
			set(newValue) {
				this.$emit('input', newValue)
			},
			get() {
				return this.value
			},
		},
	},
}
</script>

<style scoped>
.select-class {
	@apply text-sm;
}

label {
	@apply block font-medium;
}

select {
	@apply border border-gray-200;
	@apply w-full rounded px-4 py-3 mt-2;
}
</style>
