<template>
	<div class="text-sm">
		<label :for="$attrs.id" class="flex items-center gap-2">
			<input type="checkbox" :id="$attrs.id" v-model="val">
			<slot></slot>
			<span v-if="required">*</span>
		</label>
	</div>
</template>

<script>
export default {
	name: 'BaseCheckbox',
	props: {
		value: {
			type: [Boolean, Number],
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		}
	},
	computed: {
		val: {
			set(newValue) {
				this.$emit('input', newValue)
			},
			get() {
				return this.value
			},
		},
	},
}
</script>
