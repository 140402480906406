<template>
	<section class="bg-white px-10 pb-10 pt-24">
		<div class="flex items-end justify-between">
			<div class="flex items-baseline gap-10">
				<div v-for="(route, i) in routes" :key="i">
					<router-link :to="route.to" class="link" v-if="route.condition">
						{{ route.name }}
					</router-link>
				</div>
			</div>
			<h2>{{ title }}</h2>
		</div>
	</section>
</template>

<script>
export default {
	name: 'BaseHeader',
	props: {
		title: String,
		routes: Array,
	},
}
</script>

<style scoped>
.link {
	@apply text-neutral tracking-wider;
}

.link.router-link-exact-active {
	@apply relative;
	@apply font-semibold text-black;
}

.link.router-link-exact-active:after {
	content: '';
	@apply absolute -bottom-1 left-0 w-full;
	@apply h-1 bg-primary rounded-full;
}
</style>