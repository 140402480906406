import store from '@/core/store'

export default async function (fn) {
	store.commit('SET_ERROR', null)
	store.commit('SET_LOADING', true)
	try {
		const { data, status } = await fn

		return {
			data: data?.data,
			status,
		}
	} catch (err) {
		store.commit('SET_ERROR', err)
	} finally {
		store.commit('SET_LOADING', false)
	}
}
