<template>
	<div>
		<h1>Base Modal</h1>
	</div>
</template>

<script>
export default {
	name: 'BaseModal'
}
</script>
