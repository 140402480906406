<template>
	<button v-bind="$attrs" :class="{transparent}" @click="$emit('click')">
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: 'BaseButton',
	props: {
		transparent: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>
button {
	@apply font-medium text-white tracking-widest text-sm;
	@apply bg-primary py-3 px-10 rounded self-start;
}

.transparent {
	@apply bg-gray-300 text-black;
}

button[disabled] {
	@apply text-gray-400;
	@apply bg-gray-200 cursor-not-allowed;
}
</style>