<template>
	<div class="chip">
		<div class="inner-chip">
			<slot></slot>
			<button type="button" title="Ta bort" @click="$emit('remove')" v-if="remove">
				<XIcon size="15" class="text-gray-400"/>
			</button>
		</div>
	</div>
</template>

<script>
import {XIcon} from '@vue-hero-icons/outline'

export default {
	name: 'BaseChip',
	components: {
		XIcon,
	},
	props: {
		remove: {
			type: Boolean,
			default: true,
		},
	},
}
</script>

<style scoped>
.chip {
	@apply inline-block;
	@apply text-xs font-semibold;
	@apply bg-gray-200 py-2 px-4 my-2 rounded-full;
}

.inner-chip {
	@apply flex items-start gap-2;
}
</style>