import addRoutes from '@/core/helpers/addRoutes'

addRoutes([
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "Profile" */ '@/modules/profile/views/Profile.vue'),
		children: [
			{
				path: 'general',
				name: 'ProfileGeneral',
				component: () => import(/* webpackChunkName: "ProfileGeneral" */ '@/modules/profile/views/ProfileGeneral.vue'),
			},
			{
				path: 'applications',
				name: 'ProfileApplications',
				component: () => import(/* webpackChunkName: "ProfileApplications" */ '@/modules/profile/views/ProfileApplications.vue'),
			},
		],
	},
])