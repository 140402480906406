<template>
	<div class="select-class">
		<label for="search">
			<slot/>
			<span v-if="required">*</span>
		</label>
		<Multiselect
			id="search"
			v-model="val"
			:options="options"
			@search-change="searchListener"
			:custom-label="customLabel"
			:placeholder="placeholder"
		>
			<template slot="noResult">
				Inget resultat.
			</template>

			<template slot="noOptions">
				Det finns inga val att välja mellan.
			</template>

			<template slot="singleLabel" slot-scope="props">
				{{ props.option.first_name }} {{ props.option.last_name }}
			</template>
		</Multiselect>
	</div>
</template>

<script>
import debounce from 'lodash.debounce'
import Multiselect from 'vue-multiselect'
import UserService from '@/core/services/UserService'
import basicApiCall from '@/core/helpers/basicApiCall'

export default {
	name: 'BaseUserSearch',
	components: {
		Multiselect,
	},
	props: {
		value: {
			type: [String, Object],
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		preFetch: {
			type: Boolean,
			default: false,
		},
		params: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		placeholder: null,
		options: [],
	}),
	computed: {
		val: {
			set(newValue) {
				this.$emit('input', newValue)
			},
			get() {
				return this.value
			},
		},
	},
	methods: {
		placeholderListener() {
			if (this.$attrs.placeholder) {
				this.placeholder = this.$attrs.placeholder
			} else {
				this.placeholder = this.$slots.default[0].text
			}
		},
		customLabel({first_name, last_name}) {
			return `${first_name} ${last_name}`
		},
		searchListener(search, action) {
			search = search.trim()
			if (search === '') {
				return
			}

			this.search(action, search, this)
		},
		search: debounce(async (action, search, vm) => {
			const {data} = await basicApiCall(UserService.index(vm.params))
			vm.options = data
		}, 350),
	},
	async created() {
		this.placeholderListener()

		if (this.preFetch) {
			const {data} = await basicApiCall(UserService.index(this.params))
			this.options = data
		}
	},
}
</script>

<style>
.select-class {
	@apply text-sm;
}

label {
	@apply block font-medium;
}

.multiselect__placeholder {
	@apply text-gray-400;
}

.multiselect__tags {
	@apply border border-gray-200;
	@apply w-full rounded px-4 py-3 mt-2;
}

.multiselect__input {
	@apply focus:outline-none;
}

.multiselect__content {
	@apply w-full rounded-b;
	@apply border-b border-l border-r border-gray-200;
}

.multiselect__option {
	@apply py-2 px-3 block w-full cursor-pointer;
	@apply border-b border-gray-200;
}
</style>