import axios from 'axios'
import router from './router.js'
import store from '@/core/store'

export default () => {
	let client = axios.create({
		baseURL: `${process.env.VUE_APP_API}/api/v1`,
		headers: {
			Authorization: `Bearer ${store.getters['AuthStore/token']}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	})

	client.interceptors.response.use(res => {
		return res
	}, error => {
		if (error.response.status === 401) {
			router.replace('/').catch(err => console.log(err))
		}
		return error
	})

	return client
};
