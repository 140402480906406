<template>
	<router-link :to="to" class="link" :class="[{small}, {transparent}]">
		<slot></slot>
	</router-link>
</template>

<script>
export default {
	name: 'BaseLinkButton',
	props: {
		to: {
			type: [String, Object],
			required: true,
		},
		small: {
			type: Boolean,
			default: false,
		},
		transparent: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>
.link {
	@apply inline-block;
	@apply font-medium text-white tracking-wider text-sm;
	@apply bg-primary py-3 px-10 rounded self-start;
}

.transparent {
	@apply bg-gray-200 text-black;
}

.small {
	@apply text-black px-5 py-2 rounded-full;
}
</style>