<template>
	<div class="input-class" :class="{tight}">
		<label for="$attrs.id">
			<slot></slot>
		</label>
		<div class="input-wrapper">
			<input
				type="search"
				v-bind="$attrs"
				:placeholder="placeholder"
				:value="value"
				@input="(e) => $emit('input', e.target.value)"
			>
			<SearchIcon size="20" class="icon"/>
		</div>
	</div>
</template>

<script>
import {SearchIcon} from '@vue-hero-icons/outline'

export default {
	name: 'BaseInput',
	components: {SearchIcon},
	props: {
		value: [String, Number],
		tight: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			required: true,
		},
	},
}
</script>

<style scoped>
.input-class {
	@apply text-sm;
}

label {
	@apply block font-medium;
}

input {
	@apply border border-gray-200;
	@apply w-full rounded pl-12 pr-4 py-3 mt-2;
}

input:focus {
	@apply outline-none;
}

.input-wrapper {
	@apply relative;
}

.icon {
	@apply text-gray-400 absolute left-4 top-8 transform -translate-y-1/2;
}
</style>
