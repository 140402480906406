import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AuthStore from '@/core/stores/AuthStore'
import ActivityStore from '@/core/stores/ActivityStore'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: [
				'AuthStore.user',
				'AuthStore.token',
			],
		}),
	],
	strict: true,
	state: {
		error: null,
		loading: false,
	},
	mutations: {
		SET_ERROR(state, error) {
			state.error = error
		},
		SET_LOADING(state, loading) {
			state.loading = loading
		},
	},
	getters: {
		error: () => state => state.error,
		loading: () => state => state.loading,
	},
	modules: {
		AuthStore,
		ActivityStore,
	},
})
