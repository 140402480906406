import addRoutes from '@/core/helpers/addRoutes'
import {isAlreadyLoggedIn} from '@/core/guards/auth.guard'

addRoutes([
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "Login" */ '@/modules/login/views/Login.vue'),
		beforeEnter: isAlreadyLoggedIn,
	},
])