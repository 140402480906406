import basicApiCall from '@/core/helpers/basicApiCall'
import ActivityService from '@/core/services/ActivityService'

export default {
	namespaced: true,
	state: {
		activity: null,
		activities: null,
	},
	actions: {
		async index({commit}) {
			const {data, status} = await basicApiCall(ActivityService.index())
			if (status === 200) commit('SET_ACTIVITIES', data)
		},
		async view({commit}, id) {
			const {data, status} = await basicApiCall(ActivityService.view(id))
			if (status === 200) commit('SET_ACTIVITY', data)
		},
	},
	mutations: {
		SET_ACTIVITIES(state, activities) {
			state.activities = activities
		},
		SET_ACTIVITY(state, activity) {
			state.activity = activity
		},
		CLEAR_ACTIVITY(state) {
			state.activity = null
		},
		CREATE_NEW_ACTIVITY(state) {
			state.activity = {
				title: '',
			}
		},
	},
	getters: {
		activity: (state) => state.activity,
		activities: (state) => state.activities,
	},
}
