<template>
	<div class="footer-wrapper">
		<footer>
			<span class="text-2xl">Footer</span>
		</footer>
	</div>
</template>

<script>
export default {
	name: 'TheFooter'
}
</script>

<style scoped>
.footer-wrapper {
	@apply h-44 bg-gray-500 text-white;
}

footer {
	@apply container mx-auto px-10;
	@apply h-full flex items-center justify-between;
}

nav {
	@apply flex items-center gap-10;
}
</style>