import addRoutes from '@/core/helpers/addRoutes'

addRoutes([
	{
		path: '/activity/create',
		name: 'ActivityCreate',
		component: () => import(/* webpackChunkName: "ActivityCreate" */ '@/modules/activity/views/ActivityCreate.vue'),
	},
	{
		path: '/activity/:id',
		name: 'Activity',
		component: () => import(/* webpackChunkName: "Activity" */ '@/modules/activity/views/Activity.vue'),
		children: [
			{
				path: 'general',
				name: 'ActivityGeneral',
				component: () => import(/* webpackChunkName: "ActivityGeneral" */ '@/modules/activity/views/ActivityGeneral.vue'),
			},
			{
				path: 'application',
				name: 'ActivityApplication',
				component: () => import(/* webpackChunkName: "ActivityApplication" */ '@/modules/activity/views/ActivityApplication.vue'),
			},
			{
				path: 'applications',
				name: 'ActivityApplications',
				component: () => import(/* webpackChunkName: "ActivityApplications" */ '@/modules/activity/views/ActivityApplications.vue'),
			},
			{
				path: 'attendance',
				name: 'ActivityAttendance',
				component: () => import(/* webpackChunkName: "ActivityAttendance" */ '@/modules/activity/views/ActivityAttendance.vue'),
			},
			{
				path: 'payment_status',
				name: 'ActivityPaymentStatus',
				component: () => import(/* webpackChunkName: "ActivityPaymentStatus" */ '@/modules/activity/views/ActivityPaymentStatus.vue'),
			},
		],
	},
])